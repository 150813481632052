import React from 'react'
import ManPower01 from '../asst/images/06.jpg';
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';

export default function ManPower(props) {
    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].manpower}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section mt-5 parllex h-40" style={{ background: `url(${ManPower01})`}} >
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="card bg-white rounded-md">
                            <div className="card-body">
                                <h6 className="text-primary fw-normal mb-2">{LANG[props.lang].title}</h6>
                                <h4 className="fw-semibold mb-4">{LANG[props.lang].manpower01} <br/> <span className="text-primary typewrite" ><span className="wrap">{LANG[props.lang].manpower02}</span></span> <br/> .</h4>
                                <p className="text-muted mb-0">{LANG[props.lang].manpower03}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div id="jarallax-container-0" ><div ></div></div></section>
    </>
    )
}
