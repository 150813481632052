import React from 'react'
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';

export default function OwnerRegistration(props) {
    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].OwnerRegistration}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-7">
                        <form class="rounded shadow p-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Name :<span class="text-danger">*</span></label>
                                        <input name="unavu_vendor_name" id="unavu_vendor_name" type="text" class="form-control" placeholder="First Name :"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email :<span class="text-danger">*</span></label>
                                        <input name="unavu_vendor_mail" id="unavu_vendor_mail" type="email" class="form-control" placeholder="Your email :"/>
                                    </div> 
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Phone no. :<span class="text-danger">*</span></label>
                                        <input name="unavu_vendor_mobile" id="unavu_vendor_mobile" type="number" class="form-control" placeholder="Your phone no. :"/>
                                    </div> 
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Company Name :</label>
                                        <input name="unavu_vendor_cname" id="unavu_vendor_cname" class="form-control" placeholder="Company Name :"/>
                                    </div>                                                                               
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Products details :</label>
                                        <textarea name="unavu_vendor_pdetailsd" id="unavu_vendor_pdetailsd" rows="4" class="form-control" placeholder="Products details :"></textarea>
                                    </div>
                                </div> 
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Address :</label>
                                        <textarea name="address" id="address" rows="4" class="form-control" placeholder="Address :"></textarea>
                                    </div>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-sm-12 flex-end">
                                    <input type="button" id="save" name="save" class="submitBnt btn btn-primary" value="Save"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

           
        </section>
    </>
    )
}
