import React from 'react'
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';

export default function Fees(props) {
    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].ourfees}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-4 pb-2">                        
                        <p className="text-muted para-desc mx-auto mb-0">{LANG[props.lang].ourfees01}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 m-4">
                    <div id="monthly" className="wrapper-full">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="pricing text-center rounded position-relative shadow">
                                    <div className="price-header rounded-top bg-primary bg-white-dark pt-5 pb-5">
                                        <h5 className="price-title text-white title-dark">{LANG[props.lang].ourfees02}</h5>
                                    </div>
                                    <div className="pricing-features text-start">
                                        <ul className="feature list-unstyled py-4 p-3 mb-0">
                                            <li className="feature-list text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right-circle fea icon-sm text-dark me-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>{LANG[props.lang].ourfees03}</li>
                                            <li className="feature-list text-muted mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right-circle fea icon-sm text-dark me-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>{LANG[props.lang].ourfees04}</li>
                                            <li className="feature-list text-muted mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right-circle fea icon-sm text-dark me-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>{LANG[props.lang].ourfees05}</li>
                                        </ul>
                                        <div className="border-top text-center py-4">
                                            <p className='text-muted'>{LANG[props.lang].ourfees06}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="pricing text-center rounded position-relative shadow">
                                    <div className="ribbon ribbon-right ribbon-warning overflow-hidden"></div>
                                    <div className="price-header rounded-top bg-primary bg-white-dark pt-5 pb-5">
                                        <h5 className="price-title text-white title-dark">{LANG[props.lang].ourfees07}</h5>
                                    </div>
                                    <div className="pricing-features text-start">
                                        <div className="border-top text-center p-4">
                                            <p className='text-muted'>{LANG[props.lang].ourfees08}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="pricing text-center rounded position-relative shadow">
                                    <div className="price-header rounded-top bg-primary bg-white-dark pt-5 pb-5">
                                        <h5 className="price-title text-white title-dark">{LANG[props.lang].ourfees09}</h5>
                                    </div>
                                    <div className="pricing-features text-start">
                                        <div className="border-top text-center p-5">
                                            <p className='text-muted'>{LANG[props.lang].ourfees10}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
