import React from 'react'
import two from '../asst/images/02.jpg';
import cta from '../asst/images/cta.jpg';
import services from '../asst/images/services-1.png';
import LANG from '../asst/JSON/lang.json';

export default function Services(props) {
    return (<>
        <section className="bg-half-170 d-table w-100" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].ourservices}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="me-lg-5">
                            <img loading="lazy"src={services} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title">
                            <h6 className="fw-normal text-primary">{LANG[props.lang].title}</h6>
                            <h4 className="title mb-4">{LANG[props.lang].ourservices01}</h4>

                            <p className="text-muted mb-0">{LANG[props.lang].ourservices02}</p>
                        
                            <div className="mt-4">
                                <h5 className="fw-normal text-center">{LANG[props.lang].ourservices03}</h5>
                                <div className='mt-4'>
                                    <div className='btn btn-pills btn-soft-primary m-2'>{LANG[props.lang].ourservices04}</div>
                                    <div className='btn btn-pills btn-soft-primary'>{LANG[props.lang].ourservices05}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title">
                            <h6 className="fw-normal text-primary">{LANG[props.lang].title}</h6>
                            <h4 className="title mb-4">{LANG[props.lang].ourservices06}</h4>

                            <p className="text-muted mb-0">{LANG[props.lang].ourservices07}</p>
                        
                            <div className="mt-4">
                                <h5 className="fw-normal text-center">{LANG[props.lang].ourservices03}</h5>
                                <div className='mt-4'>
                                    <div className='btn btn-pills btn-soft-primary m-2'>{LANG[props.lang].ourservices04}</div>
                                    <div className='btn btn-pills btn-soft-primary'>{LANG[props.lang].ourservices08}</div>
                                    <div className='btn btn-pills btn-soft-primary'>{LANG[props.lang].ourservices09}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="me-lg-5">
                            <img loading="lazy"src={two} className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-100 mb-60 ">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="me-lg-5">
                            <img loading="lazy"src={cta} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title">
                            <h6 className="fw-normal text-primary">{LANG[props.lang].title}</h6>
                            <h4 className="title mb-4">{LANG[props.lang].ourservices10}</h4>

                            <p className="text-muted mb-0">{LANG[props.lang].ourservices11}</p>
                        
                            <div className="mt-4">
                                <h5 className="fw-normal text-center">{LANG[props.lang].ourservices03}</h5>
                                <div className='mt-4'>
                                    <div className='btn btn-pills btn-soft-primary m-2'>{LANG[props.lang].ourservices04}</div>
                                    <div className='btn btn-pills btn-soft-primary'>{LANG[props.lang].ourservices05}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
}
