import React from 'react'
import popup from '../asst/images/popup.jpg';
import two from '../asst/images/02.jpg';
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';
import { NavLink } from 'react-router-dom';


export default function AboutUs(props) {

  return (<>
    <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
      <div className="bg-overlay bg-gradient-overlay-2"></div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="title-heading text-center">
              <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].aboutus}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="position-relative">
      <div className="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <section className="section" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="about-image position-relative">
              <img loading="lazy"src={popup} className="img-fluid rounded shadow" alt="" />
              <div className="about-image-position">
                <img loading="lazy"src={two} className="img-fluid rounded shadow-lg" alt="" />
                <div className="play-icon">
                  <NavLink href="#!" data-type="youtube" data-id="bYhYMzNaTbM" className="play-btn lightbox">
                    <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-2 h-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                      </svg>
                    </i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h6 className="text-primary fw-normal">{LANG[props.lang].title}</h6>
              <h4 className="title mb-4">{LANG[props.lang].aboutUsSub}</h4>
              <p className="text-muted">{LANG[props.lang].aboutusC1}</p>
              <p className="text-muted mb-0">{LANG[props.lang].aboutusC2}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-two bg-light">
      <div className="container">
        <h4 className='text-center'>{LANG[props.lang].aboutusC3}</h4>
        <div className="row">
          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center rounded">
                <i className="uil uil-presentation-line d-block rounded h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                  </svg>

                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">
                  {LANG[props.lang].aboutusC4} </NavLink>
              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>

          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-bill d-block h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                  </svg>
                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC5} </NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>

          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-money-withdrawal d-block h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                  </svg>
                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">
                {LANG[props.lang].aboutusC6} </NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>

          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-presentation-line d-block h4 mb-0 p-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                  </svg>

                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC7} </NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>

          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-usd-circle d-block h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
                  </svg>

                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC8} </NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>

          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                  </svg>


                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC9}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                  </svg>


                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC10}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                  </svg>
                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC11}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                  </svg>


                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC12}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
          <div className="col-sm-4 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                  </svg>



                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC13}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
          <div className="col-sm-8 mt-4 pt-2">
            <div
              className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
              <div className="icons text-center">
                <i className="uil uil-invoice d-block h4 mb-0 p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z" />
                  </svg>
                </i>
              </div>
              <div className="content mt-4">
                <NavLink to="/our-services" className="h5 text-dark title">{LANG[props.lang].aboutusC14}</NavLink>

              </div>
              <div className="feature-ovelay-one"></div>
              <div className="feature-ovelay-two"></div>
              <div className="feature-ovelay-three"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="section-title text-center mb-4 pb-2">
              <p className="text-muted mx-auto para-desc mb-0">{LANG[props.lang].aboutusC15}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC16} </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC17}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC18}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC19}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC20}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC21}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC22}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC23}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC24}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC25}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
              <h5>{LANG[props.lang].aboutusC26}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-5">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="content mt-4 pt-2">
                <h5>{LANG[props.lang].aboutusC27}</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            <div className="section-title text-center mb-4  mt-100 pb-2">
              <p className="text-muted mx-auto para-desc mb-0">{LANG[props.lang].aboutusC28}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
